.isi {
    text-align: center;
    color: #06283D;
    font-size: 3rem;
    font-weight: 400;
    text-transform: capitalize;
    padding: 2rem 2rem;
    background-color: #FFFF;
    border-bottom: 0.4px solid;
    border-color: rgba(247, 160, 84, 0.4);
}
.isi h1 {
    color: rgb(6, 40, 61);
    font-size: 5rem;
    font-weight: 500;
    text-transform: capitalize;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding-top: 2rem;
    width: 60%;
    margin: auto;
    margin-bottom: 20px;
}
.infContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    align-items: center;
}
.txtContainer {
    width: 40%;
    min-width: 40rem;
    padding: 5rem;
    box-shadow: 0 0 2px rgb(58, 180, 242,0.637);
    text-align: center;
    align-self: stretch;
    margin-bottom: 5rem;
    margin: .5rem;
}

.txtContainer img {
    width: 100%;
}

.txtContainer img:hover{
    cursor: pointer;
    box-shadow: 0 0 5px #F6F6F6,
                0 0 10px #F2DF3A,
                0 0 20px #3AB4F2;
}

.txtContainer h4{
    font-size: 2.1rem;
    color: #0078AA;
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
}

.b button{
   margin-top: 2rem;
    border:1.8px solid #06283D;
    background-color: rgba(40, 174, 199,0.5);
    padding: 15px 20px;
    width: 200px;
    height: 50px;
    transition: .5s ease;
  
}

.txtContainer button:hover{
    background-color: rgba(40, 174, 199);
    color: #06283D;
  
}

@media screen and (max-width:950px) {
    
    .txtContainer{
        width: 70%;
    }
    .isi h1{
        font-size: 4rem;
    }

    
}