.container{
    background-image: linear-gradient(
        to right bottom ,
        rgba(0, 1, 5, 0.884),
        rgba(6, 54, 143, 0.897));
    background-size: cover;
    background-position: center;
    padding-bottom: 10rem;
}
.container .comp{
    width:80%;
    margin: auto;
    background-color: rgb(255, 255, 255,0.9);
}

.container .title h1{
    text-align: center;
    font-size: 12rem;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    letter-spacing: 1rem;
    color: rgb(200, 232, 225);
}

.container .comp h2{
    font-size: 4rem;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.container .comp p{
    font-size: 2.45rem;
    text-align: center;
    width: 80%;
    margin: auto;
    padding-bottom: 40px;
}

.lower{
    display: flex;
    flex-wrap: wrap;
    
}

.lower .left{
    width: 50%;
    padding: 20px;
    min-width: 300px;
}

.lower .right{
    width: 50%;
    padding: 20px;
    min-width: 300px;
}

.lower .left p,
.lower .right p{
    font-size: 2.1rem;
    text-align: left;
    padding-top: 30px;
}
h3{
    font-size: 3rem;
    color: rgb(6, 59, 77);
    text-align: center;
}

.container .title2 h1 {
    text-align: center;
    font-size: 10rem;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    letter-spacing: 0.5rem;
    color: rgb(200, 232, 225);
    animation: moveInleft 1s ease-out;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.cardlist{
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    justify-content: center;
    margin: auto;
}

.cardlist li {
   margin: 1rem;
}

@media  screen and (max-width:800px) {
    .lower .left,
    .lower .right{
        width: 100%;
    }
    .container .title2 h1 {
        font-size: 8rem;
    }

}