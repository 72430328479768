.results{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
    align-items: center;
    width: 100%;
}

.character{
    width: 80%;
    box-sizing: border-box;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.349);
    border-radius: 2rem;
    padding: 3rem; 
    margin-bottom: 5rem;
}
.character h1{
    font-size: 4rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    text-align: center;
    display: flex;
    justify-self: flex-start;
}

.charbody{
    display: flex;
    align-items: center;
    justify-items: center;
    
}

.charbody img{
    width: 20rem;
    height: 20rem;
}

.charbody p{
    font-size: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    word-break: break-word;
}

.chardetails{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-self: flex-start;
}
@media  screen and (max-width:1120px) {
    .charbody{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        
    }
    .character h1{
        font-size: 4rem;
        margin-bottom: 2rem;
        margin-left: 1rem;
         justify-content: center;
    }
    .charbody p{
        font-size: 1.8rem;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        word-break: break-word;
    }
}