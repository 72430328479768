.nav{
    padding: 5rem 10%;
    color: #0a283d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: 10rem;
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.349);
    z-index: 5;
    position: relative;
    -ms-user-select:none;
    -moz-user-select:none;
    -webkit-user-select:none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
     user-select:none;
}
.logoContainer {
    display: flex;
    align-items: center;
}
.imgContainer {
    width: 12rem;
    margin-right: 1.5rem; 
}
.imgContainer img {
    width: 100%;
   
}
.navOptions ul li {
    display: inline-block;
    margin-left: 3rem;
    padding: 1rem 0;
}

.navOptions ul li a {
    color:#0a283d;
    font-size: 2rem;
    font-weight: 600;
    padding: 1rem 1rem;
    transition: .5s ease;
    border-radius: 15px;
    border: solid 2px #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

}
.bur {
    display: none;
    z-index: 1;
}

.options .activeLink  {
   border: solid 2px #0a283d;
   border-radius: 15px;
}

.options .activeLinkL {
    background-color: #0a283d;
    color: #ffffff;
}

.options .loginL
{
    background-color: #0a283d;
    color: #ffffff;
}

.navOptions ul li a:hover {
    border: solid 2px #0a283d8a;
   border-radius: 15px;

}
.navOptions ul li h2 {
    cursor: pointer;
    padding: 1rem;
    border-radius: 10px;
}

.back {
    position: fixed;
    background-color: black;
    width: 100%;
    min-height: 200vh;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    right: 10%;
    z-index:-1;
    transition: opacity 4s ease-in-out;
    opacity: 0;
    display: none;
}

@media screen and (max-width: 1300px) {
    .nav {
        padding: 5rem 6%;
    }
}

@media screen and (max-width: 1140px) {
    .options{
        position: absolute;
        width: 30rem;
        height: 100vh;
        background-color:  #ffffff;
        padding: 10px 50px;
        align-items: center;
        transition: .5s ease;
        z-index: 5;
    }
    .optionsActive {
        right: 0;
    }
    .sleepOptions{
        right: -600px;
    }
    .bur {
        display: inline-block;
        
    }
    .navOptions {
        position: fixed;
        right: 0;
        top: 0;
        text-align: center;
       
    }
    .navOptions ul li {
        margin: 0;
        margin-top: 4rem;;
        display: block;
       
    }
   .links {
    padding-top: 10vh;
   }
   .back{
    opacity: 0.2;
    display: inline-block;
    }
}
@media screen and (max-width: 500px) {
    .imgContainer {
        width: 10rem;
        margin-right: 1.5rem; 
    }
    .nav {
        padding: 5rem 4%;
    }
    .back{
        opacity: 0.2;
        display: inline-block;
        }
}