.workexp
{
    width:90%;
}

.maintitle
{
    margin-top: 5%;
    display: flex;
    gap:20%;

}

.title
{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 1rem;
    width: fit-content;

}

.jobtitle
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.8rem;
}

.datee
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    width: fit-content;
}

.role
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    margin-left: 2.rem;
    margin-bottom: 1rem;

}