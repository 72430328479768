.txt {
    height: 28vh;
    margin-left: 10%;
    margin-bottom: 0.5%;
    margin-top: 1%;
    display: block;
}

.txt h1 {
    color: rgb(0, 0, 0);
    font-size: 1.8rem;
    font-family: Helvetica;
    font-weight: bold;
    text-align: left;
    margin-bottom: 3%;
}

/*
.txt h1::before{
    content: open-quote;
}

.txt h1::after{
    content: close-quote;
}
*/

@media screen and (max-width: 500px){
    .txt {
        height: 18vh;
        margin-bottom:0;
        margin-top: 5%;
        width: 85%;
        margin: auto;
        text-align: center;
    }
    
    .txt h1 {
        color: rgb(0, 0, 0);
        font-size: 1.8rem;
        margin-bottom: 5%;
    }
}
