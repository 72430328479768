.container {
    padding: 1rem;
    height: 380px;
    width: 230px;
    display: block;
    background-color: rgb(255, 255, 255,0.9);
    box-shadow: 0 0 7px rgba(255, 255, 255);
}

.container .card{
    display: block;
    border-radius: 5px;
}

.container .card .image{
    width: 90%;
    margin: auto;
    box-shadow: 0 0 5px grey;
    border-radius: 2px;
}

.container .card .image img {
    width: 100%;
}

.card h3{
    font-weight: 200;
    padding-top: 2rem;
    text-align: center;
 
}