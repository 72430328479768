/* prevents scrolling on open sidebar */
html :has(.expand){
    overflow: hidden !important;        
}

.sidebar 
{
    width: 15.5vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    background-image: linear-gradient(#1E2FA5,#0C1552);
    border-top-right-radius: 50px;
    gap:1.5rem;
    position: sticky;
    transition: .5s ease;
    -ms-user-select:none;
    -moz-user-select:none;
    -webkit-user-select:none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
     user-select:none;
}

.links{
    position: fixed;
    top: 20%;
    display: flex;
    flex-direction: column;
    gap:14px;
    width: 15.5%;
}

.navOptions ul li 
{
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-weight:620;
    font-family: Segoe UI;
    color: white;
    padding: 5% 0 5% 0;
    height: fit-content;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.25s;
    height: 70px;
    
}
.navOptions ul li a{
    color: white;
    padding-left: 20%;
}
.navOptions ul li:hover 
{
    background-color: #3B55FF;
    color: white;
    padding: 5% 0 5% 0;
    cursor: pointer;
    transition: background-color 0.5s;
    height: 70px;
}

.options .activeLink {
    background-color: #3B55FF;
    border-left: 5px solid white;
    display: flex;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s;
    height: 70px;
    width: 29vw;
    transition: .5s ease;
}

.op{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: min(18rem, 150%);
}

.op p{
    font-size: 1.8rem;
}
.bur {
    display: none;
    z-index: 1;
}
.back {
    position: fixed;
    background-color: black;
    height: 100%;
    width: 100vw;
    /* left: 23%; */
    min-height: 200vh;
    background-position: center;
    background-size: cover;
    /* overflow: hidden; */
    z-index:-1;
    transition: opacity 4s ease-in-out;
    opacity: 0;
    display: none;
}

.arrow{
    color: white;
    font-size: 4rem !important;
    margin-top: 50%;
}
.activeShrink{
    background-color: #3B55FF;
    border-left: 5px solid white;
    display: flex;
    /* gap: 12px; */
    font-size: 22px;
    font-weight:620;
    font-family: Segoe UI;
    color: white;
    /* padding: 5% 0 5% 20%; */
    height: fit-content;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s;
    height: 70px;
    width: 100%;
    /* transition: .5s ease; */
}

@media screen and (max-width: 1030px) {
    .disappear {
        display: none;
    }
    .bur {
        display: block;  
    }
}
@media screen and (max-width: 1140px) {
    .expand{
        min-height: 100vh;
        display: flex;
        flex-direction: row;
        background-image: linear-gradient(#1E2FA5,#0C1552);
        border-top-right-radius: 50px;
        /* gap:25px; */
        width: 33vw;
        overflow: auto;
        /* position: fixed; */
        transition: .5s ease;
    }


    .appear{
        display: unset;
    }
    .optionsActive {
        right: 0;
    }
    .sleepOptions{
        right: -600px;
    }
    .navOptions {
        left: 0;
        top: 0;       
    }

    .navOptions ul li {
        margin: 0;
        margin-top: 4rem;;
        display: block;
       
    }
   .links {
    padding-top: 10vh;
   }

   .back{
    position: absolute;
    opacity: 0.2;
    display: inline-block;
    }
}
