.layout {
    height: 90vh;
    background-image: linear-gradient(
        to right bottom ,
        rgba(0, 1, 5, 0.884),
        rgba(6, 54, 143, 0.897)), 
        url("../../assets/bg.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 4;
}

.fix {
    z-index: -22;
}
.textBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
  
}
.layoutTitle {
    color: #fff;
    text-transform:capitalize;
    backface-visibility: hidden;
    margin-bottom: 6rem;
}
.layoutTitleMain {
    display: block;
    font-size: 5rem;
    font-weight: 600;
    letter-spacing: 1rem;
    animation: moveInleft 1s ease-out;
    margin-bottom: 2rem;
}
.layoutTitlesub {
    width: 100%;
    display: block;
    font-size: 1.8rem ;
    font-weight: 700;
    letter-spacing: .5rem;
    animation: moveInright 1s ease-out;
    line-height: 4rem;
    margin: auto;
  
}
.btn {
    position: relative;
    display: inline-block;
    padding: 30px 40px;
    font-size: 2rem;
    color: #03e9f4;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}

.btn:hover{
    background: #03e9f4;
    color: #050801;
    box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 150px #03e9f4;
}
.btn span{
    position: absolute;
    display: block;
}
.btn span:nth-child(1){
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg,transparent,#03e9f4);
    animation: animate1 1s linear infinite;
}
@keyframes animate1{
    0%{
        left: -100%;
    }
    50%,100%{
        left: 100%;
    }
}
.btn span:nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg,transparent,#03e9f4);
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
}
@keyframes animate2{
    0%{
        top: -100%;
    }
    50%,100%{
        top: 100%;
    }
}
.btn span:nth-child(3){
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg,transparent,#03e9f4);
    animation: animate3 1s linear infinite;
    animation-delay: 0.50s;
}
@keyframes animate3{
    0%{
        right: -100%;
    }
    50%,100%{
        right: 100%;
    }
}


.btn span:nth-child(4){
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg,transparent,#03e9f4);
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
}
@keyframes animate4{
    0%{
        bottom: -100%;
    }
    50%,100%{
        bottom: 100%;
    }
}
@keyframes moveInleft {
    0%{
        opacity: 0;
        transform: translateX(-10rem);
    }

    80%{
        transform: translateX(1rem);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
    
}

@keyframes moveInright {
    0%{
        opacity: 0;
        transform: translateX(10rem);
    }

    80%{
        transform: translateX(-1rem);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
    
}
@keyframes moveIntop {
    0%{
        opacity: 0;
        transform: translateY(3rem);
    }

  
    100% {
        opacity: 1;
        transform: translate(0);
    }
    
}