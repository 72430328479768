.body{
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-image: linear-gradient(
        to right bottom ,
        rgba(0, 1, 5, 0.884),
        rgba(6, 54, 143, 0.897));
    background-size: cover;
    background-position: center;
}

.body1{
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-image: linear-gradient(
        to right bottom ,
        rgba(0, 1, 5, 0.884),
        rgba(6, 54, 143, 0.897));
    background-size: cover;
    background-position: center;
}