html {
    scroll-behavior: smooth;
  }

.Boss
{
    width: 80%;
    padding: 5%;
}

.Boss .form h1{
    width: 60%;
    margin: auto;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 5rem;
    margin-bottom: 2rem;
    color: #0A283D;
}

.Boss .form h3{
    text-align: left;
    width: fit-content;
    margin-left: 10%;
    margin-top: 5rem;
    margin-bottom: 4rem;
    font-size: 2.7rem;

    border-bottom: 3px solid transparent;
    border-image: linear-gradient(45deg, #17289C 5%, white 95%);
    border-image-slice: 1;
}

.Boss .form h2{
    color: #0A283D;
    width: 90%;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 4rem;
    font-size: 2rem;

}

.Boss .form .info{
    width:90%;
    padding: 4%;
    margin: auto;
    margin-bottom:5rem;
    font-size: 2.2rem;
    background-color: #F1F1F1;
    border-radius: 2rem;

    margin-bottom: 2rem;
    font-weight: 600;
    color: #0A283D;
}

.Boss .form .info ul{
    margin-top: 2rem;
    list-style-type:circle;
}

.Boss .form .info ul li{
    text-align: left;
    font-size: 1.8rem;
    font-weight: 300;
    margin-bottom: 1rem;
    width: 90%;
    margin: auto;
    color: #0A283D;
    list-style: none;
    margin-bottom: 1rem;
}

.Boss .form .info ul li::before {

    /* Unicode for a bullet */
    content: "\2022";
      
    /* Styles for Indentation*/
    color: #17289C;
      
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.shape{
    flex-direction: column;
    align-items: center;
}

.inputbox{
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin: 20px auto;
    width: 60%;
}
.input {
    width: 100%;
    height: 15rem;
    box-sizing: border-box;
    border: 2px solid rgba(116, 116, 116, 0.849);
    border-radius: 4px;
    font-size: 1.4rem;
    background-repeat: no-repeat;
    padding: 12px 8px;
    text-align: left;
    background-color: #F1F1F1;
    margin-top: 5px;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
}

.label {
    padding: 2px 0;
    font-size: 1.6rem;
    color: black;
    pointer-events: none;
    transition: .5s;
}

.options{
    width: 70%;

    max-height: 80vh;
    margin-left: 7rem;

    display: flex;
    flex-direction: column;
    gap:5px;
    flex-wrap: wrap;
}

.options label{
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 20px;

    background-color: rgba(250, 250, 250, 0.989);
    width: 40%;
    height: 6vh;
    margin: auto;
    text-align: center;
    margin-bottom: 1rem;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.6rem;
}

.Boss input[type=checkbox] {
    visibility: hidden;
}
  
/* Creating a custom checkbox
based on demand */
.geekmark {
    position: absolute;
    top: 0.8rem;
    left: 1rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: white;
    border: 0.5px solid #707070;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}
  
/* Specify the background color to be
shown when hovering over checkbox */
.main:hover input ~ .geekmark {
    background-color: #3B55FF;
    opacity: 80%;
}
  
/* Specify the background color to be
shown when checkbox is active */
.main input:active ~ .geekmark {
    background-color: #3B55FF;
    border-color: #3B55FF;
}
  
/* Specify the background color to be
shown when checkbox is checked */
.main input:checked ~ .geekmark {
    background-color: #3B55FF;
    border-color: #3B55FF;
}
  
/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Display checkmark when checked */
.main input:checked ~ .geekmark:after {
    display: block;
}
  
/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.main .geekmark:after {
    left: 5.5px;
    bottom: 5px;
    width: 6px;
    height: 12px;
    margin: auto;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.f {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 5rem;
    padding-top: 3rem;
}


@media  screen and (max-width:700px) {
    .options{
        width: 100%;
        overflow-y: scroll;
        flex-wrap: nowrap;

        margin: auto;
    }

    .options label{
        width: 80%;
    }

    .options::-webkit-scrollbar {
        width: 0.5em;
    }
     
    .options::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
     
    .options::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
}