.object{
    width: 80%;
    margin: auto;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.749);
    border-radius: 10px;
    text-align: center;
    padding-bottom: 3rem;
}

.heading h1{
    font-size: 4.5rem;
    padding: 2.5rem 0;
    font-family: 'Segoe UI';
    color: #0A283D;
}

.heading h3 {
    font-family: Helvetica ;
    font-size: 2.2rem;
    color: #0A283D;
}

.mcq {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5rem 2rem;
    margin: auto;
    width: 50%;
}

.mcq li {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    box-shadow: 0 0 5px rgb(128, 128, 128);
    border: 2px solid #0A1D96;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    text-align: center;
}

.mcq li a {
    font-size: 2.5rem;
    font-family: Helvetica;
    font-weight: 600;
    position: relative;
    top: 30%;
    border-radius: 50%;
}

.mcq li label {
    padding-top: 5px;
    font-size: 1.4rem;
    font-weight: lighter;
    font-family: Segoe UI;
    color: black;
}

.mcq li:hover{
    background-color: #3B55FF;
    transition: all;
    transition-delay: 0.1s;
    cursor: pointer;
    color: white;
}

.active {
    background-color: #3B55FF;
    color: aliceblue;
}

.guidance{
    width: 85%;
    margin: auto;
    display: flex;
   justify-content: space-around;
}

.adjustimg{
    width:2.5rem;
    height: 2.rem;
}

.adjustimg img{
    background-size: contain;
    max-width: 100%;
}

.illustration{
    position: relative;
    width: 42rem;
    height: 42rem;
}

.illustration img{
    max-width: 100%;
    max-height: 100%;
}

.illustrationx{
    position: relative;
    width: 32rem;
    height: 32rem;
}

.illustrationx img{
    max-width: 100%;
    max-height: 100%;
}

.guidanceinfo {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.guidanceinfox {
   
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 35px;
}
.title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 3rem;
}

.title h2 {
    font-size: 2.5rem;
}
.info {
    width: 100%;
    display: block;
    text-align: left;
    padding-top: 1rem;
}

.info ul {
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.info ul li {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
    text-align: center;
}

.infop{
    font-size: 2rem;
    font-weight: 600;
    font-family: helvetica;
    padding: 5px 0;
}

.infop2{
    font-size: 1.8rem;
    font-weight: 600;
    font-family: helvetica;
    padding: 5px 0;
}

.bullet {
    
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 0 0 5px rgb(128, 128, 128);
    border: 2px solid #0A1D96;
    text-align: center;
}

.bulletX{
  
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 0 5px rgb(128, 128, 128);
    border: 2px solid #0A1D96;
    text-align: center;
}

.bullet p {
    position: relative;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 1.4rem;
    font-weight:700;
}

.finish{
    display: block;
    color: white;
    cursor: pointer;
    user-select: none;
    background-color: #182AA3;
    padding: 10px;
    font-size: 19px;
    font-family: helvetica;
    font-weight: bold;
    z-index: 2;
    border-radius: 36px;
    width: 140px;
    height: 47px;
    text-align: center;
    margin: 1rem auto;
}

.finish:hover{
    background-color: #DCA500;
    opacity: 0.8;
    transition-delay: 0.05s;
}

.f {
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
@media screen and (max-width: 1250px){
   

    
    .mcq li {
        width: 3rem;
        height: 3rem;
        gap:2rem;
    }

    .mcq {
        align-items: center;
        justify-content: center;
        gap:1.7rem;
        width: 100%;
        left: 0%;
    }

    .mcq li label {
        font-size: 10px;
    }

    .mcq li {
        width: 4rem;
        height: 4rem;
    }

    .mcq li a {
        font-size: 1.5rem;
    }

    .illustration{
        display: none;
    }
    .illustrationx{
        display: none;
    }

    .guidance{
        width: 100%;
    }

    .guidanceinfox,
    .guidanceinfo{
        width: 80%;
    }

   
    .infop{
        font-size: 1.2rem;
        text-align: center;
    }

    .infop2{
        font-size: 1.2rem;
        font-weight: 550;
        text-align: left;
    }

    .bullet {
        width: 2.5rem;
        height: 2.5rem;
    }
    .bullet p {
        font-size: 11px;
    }
    .bulletX{
        display: none;
    }

    .heading h1{
        font-size: 3rem;
    }
    .heading h3{
        position: relative;
        padding: 0 15px;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 400;
    }

  
}
