.con {
    position: relative;
}
.comp{
    position: relative;
    display: block;
    width: 90%;
    margin: auto;
    padding-bottom: 5rem;
    padding-top: 5rem;
    text-align: center;
    box-shadow: 0 0 10px rgba(225, 225, 225);
    z-index: 4;
    background-color: rgba(255, 255, 255,0.8);
    margin-bottom: 20rem;

}
.titlea{
    color: #43919B;
    font-size: 3rem;
    font-weight: 800;
    text-transform: capitalize;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
   
}
.subtitle{
    color: rgb(6, 40, 61);
    font-size: 5rem;
    font-weight: 500;
    text-transform: capitalize;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
   
    margin: auto;
    margin-bottom: 2rem;
}

.cardlist{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.card {
    width: 20rem;
    height: 25rem;
    box-shadow: 0 5px 10px rgb(151, 151, 151);
    border-top: 1mm solid #0040C1;
    border-radius: 5px;
    margin: 1rem;
}
.card h1{
    text-align: center;
    padding: 15px 0;
    width: 100%;
}

.comp .work {
    width: 40%;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 3rem;
}
.comp .work img{
    max-width: 100%;
}

.par{
    font-size: 1.6rem;
    color: rgb(78, 77, 77);
    width: 90%;
    margin: auto;
   
    
}
.diagonalbox1,
.diagonalbox2 {
	background-image: linear-gradient(45deg, rgba(110, 133, 183,0.5), rgba(196, 215, 224,0.5));
    position: absolute;
    height: 90vh;
    z-index: inherit;
    width: 100%;
    top: 0;
    
} 
.diagonalbox1 {
    transform: skewY(11deg);

}
.diagonalbox2 {
    transform: skewY(-11deg);
    
}
.content { 	
	max-width: 50em;
    margin: 0 auto; 
}
.cube{
    position: relative;
    top: -350px;
    left: 0;
    z-index: 1;
}

.cube li{
    position: relative;
    border: 2mm solid #0040C1;
    width: 150px;
    height: 150px;
    top: 0px;
    left: 0px;
    z-index: -1;
    opacity: 0;
    z-index: 2;
    overflow: hidden;
    animation: animate 15s linear infinite ease-in-out;
}
.cube li:nth-child(1)
{
    width: 30px;
    height: 30px;
    left: 1200px;
}
.cube li:nth-child(2)
{
    top: -200px;
    left: 269px;
}
.cube li:nth-child(3){
    width: 100px;
    height: 100px;
    left: 50px;
}
@keyframes animate {
    0%   {opacity: 0;
        transform: translateY(0) rotate(0deg);}
    25%  {opacity: 0.4;}
    50%  {opacity: 0.7;}
    75%  {opacity: 0.4;}
    100% {opacity: 0; transform: translateY(-800px) rotate(720deg);}
  }


@media  screen and (max-width:800px) {
    .cube li:nth-child(1){
        left: 300px;
    }
    .cube li:nth-child(2){
        display: none;
    }
    
    .comp .work {
        width: 70%;
    }
.diagonalbox1,
.diagonalbox2 {
    display: none;
}
    
}