.container {
    min-height: 90vh;
    background-image: linear-gradient(
        to right bottom ,
        rgba(0, 1, 5, 0.884),
        rgba(6, 54, 143, 0.897));
    background-size: cover;
    background-position: center;
    position: relative;


}
.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

}
.box1 h1 {
    text-align: center;
    margin-bottom: 5rem;
    font-size: 3.5rem;

}
.box1,
.box2{
    width: 40rem;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    padding: 2rem;
    margin-bottom: 2rem;

}
.box1 {
    border-radius: 10px 10px 0 0;
}
.box2 {
    border-radius: 0 0 10px 10px ;

}
.box1 label {
    font-size: 1.5rem;
    margin-left: 1rem;
}
.fieldContainer {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
}
.field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1.3rem;
    color: #fff;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    margin-bottom: 5px;
    margin-left: 1rem;
}
.field::placeholder {
    color: transparent;
}

.field:placeholder-shown ~ .label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
}

  .label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #9b9b9b;
  }

  .field:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #11998e, #38ef7d);
    border-image-slice: 1;
  }

  
  .field:focus ~ .label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #11998e;
    font-weight: 700;
  }
  .field:required, .field:invalid {
    box-shadow: none;
  }
  .fieldContainer span {
    color: red;
    font-size: 1.2rem;
    margin-left: 1rem;
  }

.btn {
    margin-top: 2rem;
    width: 100%;
    text-align: center;
}
.btn button {
    width: 20rem;
    padding: 1rem 0;
    font-size: 2rem;
    border-radius: 10px;
    background-color:#5397f0;
    border: none;
    transition: .5s;
}
.btn button:hover{
    background-color: #2e73cc;
}

.box2 p {
    font-size: 1.7rem;
    text-align: center;
}
.box2 span {
    cursor: pointer;
    color: #3a8cf7;
}
.errorMsg {
    color: red;
    font-size: 1.2rem;
    padding-top: 0.4rem;

}
.errorMsgFP {
    color: red;
    font-size: 1.2rem;
    padding-top: 0.4rem;
    padding-left: 1rem;

}
.forgotPass p{
    width: fit-content;
    font-size: 1.7rem;
    margin: auto;
    justify-content: center;
    cursor: pointer;
    color: #9b9b9b;
    padding-top: 1rem;

}
.boxParagraph {
    color: rgb(0, 0, 0);
    font-size: small;
}

.box11 h1 {
    text-align: center;
    margin-bottom: 5rem;
    font-size: 3.5rem;

}
.box11{
    width: 40rem;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    padding: 2rem;
    margin-bottom: 2rem;

}
.box11 {
    border-radius: 10px 10px 10px 10px;
}
.box11 label {
    font-size: 1.5rem;
    margin-left: 1rem;
}