.fullprofile
{
    width: 80%;
    margin: auto;

}
.badge
{
    color: #1A2C9D;
    margin: 1rem;
    transition: 1s ease;
}
.badge:hover
{
    color: #3B55FF;
}

.maininfo
{
    border-top: 30px solid #4553AF;
    color: #0a283d;
    box-sizing: border-box;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.349);
    padding: 3rem; 
    margin-bottom: 5rem;
    
}

.userinfoContainer
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
    
}
.userInfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.userInfo .profileImage {
    width: 17rem;
    height: 17rem;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 5px 2px 4px rgb(0, 0, 0, 0.2);
    -ms-user-select:none;
    -moz-user-select:none;
    -webkit-user-select:none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
     user-select:none;
}

.userInfo .profileImage img {
    width: 100%;
}

.fieldContainer button{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 2px 3px 4px rgb(0, 0, 0, 0.16);
    overflow: hidden;
    background-color: white;
    border: none;
    margin-top: 12rem;
}

.fieldContainer button p{
    color: grey;
    font-size: 2rem;
    margin: -5px auto;
}

.profimage{
    display: flex;
    flex-direction: row;
}

@media  screen and (max-width:530px) {
    .userInfo .profileImage {
        width: 80%;
        height: 20rem;
        margin: auto;
        border-radius: 5rem;
    }
    .userInfo .info {
        width: 100%;
       text-align:center;
    }
    .userInfo .profimage {
        display: flex;
        flex-direction: column;
    }
    .fieldContainer button{
        margin-top: -10%;
    }

}



.username
{
    font-size: 2.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}

.location
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: lighter;
    width: fit-content;
}

.lowersectioninfo
{
    display: flex;
    justify-content: space-between;
    
}


.pinfo
{
    width: 45%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    
}

@media  screen and (max-width:1400px) {
   
    .pinfo
    {
        width: 100%;
    padding: 0 2rem;
        
        
    }
}
.PersonalInfo
{
    display: flex;
    justify-content: space-between;
}
@media  screen and (max-width:650px) {
.pinfo {
    padding: 0;
}
}
@media  screen and (max-width:650px) {
    .PersonalInfo{
        flex-direction: column;
    }
 
    .bdate {
        width: 90%;
        margin: auto;
    }
}

.bdate
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    margin-top: 2rem ;

}

.bdate input
{
    margin-top: 1rem ;
    background-color: #EAEAEA;
    border: none;
    height: 4.7rem;
    padding-left: 1.5rem;
    font-size: 1.8rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: lighter;
    color: #0A283D;
    opacity: 66%;
    outline: none;
    display: block;
}


.education
{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
}
.subeducation {
    margin-bottom: 1rem;

}
.subeducation label 
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.7rem;
    font-weight: 600;
 
}

.subeducation input
{
    background-color: #EAEAEA;
    border: none;
    height: 3rem;
    margin-top: 1rem;
    padding-left: 1.5rem;
    font-size: 1.8rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: lighter;
    color: #0A283D;
    opacity: 66%;
    outline: none;
}

.certIntern
{
    display: flex;
    justify-content: space-between;
    
}

.certificates
{
    width: 40%;
    border-right: #0A283D 3px solid;

}

.certificates h2,
.Internships h2
{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 5%;
}

.Internships
{
    width: 55%;
}

@media  screen and (max-width:590px) {
    .certIntern {
     flex-direction: column;
    }
    .certificates {
     width: 100%;
     border: none;
    }
    .Internships {
     width: 100%;
    }
 }

.cert
{
    margin-left: 30%;
    margin-right: 10%;
}

.mainexperience
{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.work
{
    display: flex;
    flex-direction: column;
    margin-left: 4%;
    gap: 1rem;
}

.workinfo
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
}

.skillexperience
{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2%;
    margin-top: 2%;
}

.skills
{
    display: flex;
    flex-direction: column;

}

.title
{
    display: flex;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    gap: 5px;
    margin-bottom: 1rem;
    margin-left: 3%;
}

.skillstext
{
    width: 70%;
    word-wrap: break-word;
    margin-left: 3%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    margin-top: -2%;
}

.info{
    margin-left: 2rem;
}

@media  screen and (max-width:1400px) {
    .lowersectioninfo{
        
        display: flex;
        flex-direction: column;
        gap:50px;

    }
}
.mainaboutcompany
{
    display: flex;
    gap:10rem;
}

.aboutcompany
{
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    gap: 2rem;
}

.aboutcompany p
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: normal;
}

.aboutcompany h3
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    display: flex;
    justify-self: end;
}

.abtcompsubtitle
{
    display: flex;
    align-items: center;
    gap: 5rem;
}

